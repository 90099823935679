import React from 'react';
import "assets/scss/clientManagement.scss";

const ClientManagementLayout: React.FC = ({ children }) => {
    return (
        <div className='client-management-layout'>
            {children}
        </div>
    );
};

export default ClientManagementLayout;
