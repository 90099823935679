import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAxios } from 'services/requestService';
import useModal from 'components/Modal/hook/useModal';
import RoundedLoader from 'components/Loader/RoundedLoader';
import { ReactComponent as SendSvg } from 'assets/icons/icon-mail-active.svg';
import 'assets/scss/downloadButton.scss';

interface ISendButton {
    invoiceUuid: string,
    client: any,
}

const SendButton = ({ invoiceUuid, client } : ISendButton) => {
    const { t } = useTranslation();
    const { showAlert } = useModal();
    const [loadingState, setLoadingState] = useState(false);
    const { postData } = useAxios();

    const sendEmail = () => {
        setLoadingState(true);
        postData(`invoice/${invoiceUuid}/send`, {}, true)
            .then(() => {
                setLoadingState(false);
                showAlert({
                    content: t('clientInvoice.modal.sendEmailConfirmation', { client: client })
                });
            })
            .catch((e) => {
                setLoadingState(false);
                showAlert({
                    title: t('common.modal.error.internalError.title'),
                    content: t('common.modal.error.internalError.message')
                });
            });
    };

    const modalSendEmail = () => {
        showAlert({
            content: t('clientInvoice.modal.sendEmail', { client: client }),
            onConfirm: sendEmail,
            showCancel: true
        });
    };

    return (
        loadingState
            ? <RoundedLoader isLoading={true} style={{ margin: '0' }} />
            : <SendSvg
                onClick={modalSendEmail}
            />
    );
};

export default SendButton;
