import { useState, useEffect } from 'react';

const useGetElementByIdInDom = (id: string) => {
    const [element, setElement] = useState<DOMRect | null>(null);

    useEffect(() => {
        const handleScroll = () => {
            const elementInDom = document.getElementById(id);
            if (elementInDom) {
                setElement(elementInDom.getBoundingClientRect());
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [id]);

    return element;
};

export default useGetElementByIdInDom;
