import { CustomBarChartElement } from 'components/CustomChart/CustomBarChart';
import moment from 'moment';
import { Option } from 'interfaces/option';
import { CountryAccount } from 'context/dashboardClientsContext';
import { CSSProperties } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

// used to generate X bars for chart data. See https://recharts.org/en-US/api/Bar
export const accountBars: CustomBarChartElement[] = [
    {
        dataKey: 'basic',
        stackId: 'a',
        fill: '#42D07D',
    },
    {
        dataKey: 'standard',
        stackId: 'a',
        fill: '#1DB100',
    },
    {
        dataKey: 'premium',
        stackId: 'a',
        fill: '#017100',
    },
    {
        dataKey: 'pro',
        stackId: 'a',
        fill: '#017240',
    },
    {
        dataKey: 'trial',
        fill: '#00A2FF',
    },
];

// used to generate X bars for chart data. See https://recharts.org/en-US/api/Bar
export const consumptionBars: CustomBarChartElement[] = [
    {
        dataKey: 'calls',
        yAxisId: 'left',
        fill: '#F50A6B',
    },
    {
        dataKey: 'consumption',
        yAxisId: 'right',
        fill: '#3DCF7E',
    },
];

export const yearlyOptions = (): Option[] => {
    // const { t } = useTranslation();
    const options: Option[] = [];

    // options.push({ label: t('dashboard.clients.accounts.last-year'), value: 'last' });

    for (let i = moment().year(); i > 2020; i--) {
        options.push({ label: i.toString(), value: i.toString() });
    }

    return options;
};

export const monthlyOptions = (): Option[] => {
    const options: Option[] = moment.months().map((month) => ({
        label: month,
        value: moment().month(month).format('MM'),
    }));

    return options;
};

export const dateYearOptions = (): Option[] => {
    const options: Option[] = [];

    for (let i = moment().year(); i > 2019; i--) {
        options.push({ label: i.toString(), value: `${i.toString()}-01-01` });
    }

    return options;
};

type ColumnDefinitionType = {
    key: string;
    header?: string;
    type?: string;
    style?: CSSProperties;
    align?: 'center' | 'left' | 'right' | 'justify' | 'char' | undefined;
};

export const formatCountriesTable = (t: TFunction, data: CountryAccount[]) => {
    const head: Array<ColumnDefinitionType> = [
        {
            key: 'country',
            header: t('dashboard.clients.countries.table.country'),
            type: 'text',
        },
        {
            key: 'accounts',
            header: t('dashboard.clients.countries.table.accounts'),
            type: 'text',
        },
    ];

    const body = data.map((element) => ({
        country: {
            type: 'text',
            value: element.country,
        },
        accounts: {
            type: 'text',
            value: element.total,
        },
    }));

    return { head, body };
};
