import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "components/Table/Table";
import { useAxios } from "services/requestService";
import { formatClientTable } from "components/Table/service/tableService";
import useTable from "components/Table/hook/useTable";
import DeleteTableClient from "pages/clientManagement/components/DeleteTableClient";
import { titleContext } from "components/Title/context/titleContext";
import "assets/scss/clientManagement.scss";
import { Client } from "interfaces/client";
import Filter from "pages/clientManagement/components/Filter";
import { PaginationResponse } from "interfaces/pagination";
import useClientManagement from "hooks/useClientManagent";
import useGetElementByIdInDom from "hooks/useGetElementByIdInDom";

const ClientManagementSearch = () => {
  const { t } = useTranslation();
  const { initTable, handleTotalPage, sortConfig, pageMeta, refetch } =
    useTable();
  const { updateTitle } = useContext(titleContext);
  const { getData2 } = useAxios();
  const isMounted = useRef(false);
  const tableHead = useGetElementByIdInDom("table-head");
  const pageHeader = useGetElementByIdInDom("header");
  const [windowOffsetY, setWindowOffsetY] = useState(0);
  const { state, setClientResponse, setLoading, setIsError, setError } =
    useClientManagement();
  const clientsParams = {
    sortBy: sortConfig?.key ?? "date",
    sortOrder: sortConfig?.direction ?? "DESC",
    page: pageMeta?.currentPage,
    elementsPerPage: pageMeta?.elementsPerPage,
    ...state.filter,
  };

  useEffect(() => {
    isMounted.current = true;
    (async () => {
      try {
        setLoading(true);
        const { data: clientData } = await getData2<
          PaginationResponse<Client[]>
        >("admin/clients", clientsParams);
        if (isMounted.current && clientData) {
          setClientResponse(clientData);
          handleTotalPage(clientData.totalPage);
          initTable(formatClientTable(t, clientData.elements));
          updateTitle(null, `${t("header.client-management")}`, `(${clientData.totalElements})`);
        }
        setLoading(false);
      } catch (e: unknown) {
        setLoading(false);
        setIsError(true);
        setError(e);
      }
    })();
    return () => {
      isMounted.current = false;
    };
  }, [
    sortConfig,
    pageMeta?.currentPage,
    pageMeta?.elementsPerPage,
    refetch,
    state.filter?.name,
    state.filter?.mail,
    state.filter?.support,
    state.filter?.activedServices,
  ]);

  useEffect(() => {
    const handleScroll = () => {
      setWindowOffsetY(window.pageYOffset);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="client-management-container d-flex flex-column">      
      <div className="client-management-filter d-flex mb-4 justify-content-between align-items-center">
        <Filter />
      </div>      
      <div className="middle-container">
        <Table
          // eslint-disable-next-line no-unneeded-ternary
          stickyHead={
            windowOffsetY && tableHead && tableHead?.top <= windowOffsetY
              ? true
              : false
          }
          stickyTop={`${pageHeader?.height}px`}
        />
      </div>
      {state.responseClient?.elements && (
        <div className="d-flex mt-3 justify-content-end">
          <DeleteTableClient clients={state.responseClient?.elements} />
        </div>
      )}
    </div>
  );
};

export default ClientManagementSearch;
