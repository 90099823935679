import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import FileSaver from 'file-saver';
import { authHeader } from 'services/API';
import useModal from 'components/Modal/hook/useModal';
import RoundedLoader from 'components/Loader/RoundedLoader';
import { ReactComponent as DownloadSvg } from 'assets/icons/icon-download-black.svg';
import 'assets/scss/downloadButton.scss';

interface IDownloadButton {
    filename: string,
    url: string,
    params?: any
}

const DownloadButton = ({ filename, url, params }: IDownloadButton) => {
    const { t } = useTranslation();
    const { showAlert } = useModal();
    const [loadingState, setLoadingState] = useState(false);

    const download = () => {
        setLoadingState(true);
        Axios.get(url, {
            responseType: 'blob',
            headers: authHeader(),
            params
        })
            .then(response => {
                setLoadingState(false);
                FileSaver.saveAs(response.data, filename);
            })
            .catch((e) => {
                setLoadingState(false);
                showAlert({
                    title: t('common.modal.error.internalError.title'),
                    content: t('common.modal.error.internalError.message')
                });
            });
    };

    return (
        loadingState
            ? <RoundedLoader isLoading={true} style={{ margin: '0' }} />
            : <DownloadSvg
                className='download-btn'
                onClick={download}
            />
    );
};

export default DownloadButton;
