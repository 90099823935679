import React, { CSSProperties } from 'react';
import { FieldProps } from 'formik';
import 'assets/scss/appForm.scss';
import SelectWithCheckboxes from 'components/Select/MultiSelect';
import { Option } from 'interfaces/option';

interface ISelect {
    id?: string,
    isError?: boolean,
    labelName?: string,
    options: Option[],
    placeholder: string,
    isRequired?: boolean,
    customContainerStyle?: CSSProperties
}

/*
 Custom Formik input field
*/

const AppFormMultiSelect = ({
    id,
    isError,
    labelName,
    options,
    isRequired,
    placeholder,
    field: { name, value },
    form: { errors, touched, setFieldTouched, setFieldValue },
    ...props
}: FieldProps & ISelect) => {
    const hasError = errors[name] && touched[name];

    const change = (e: any) => {
        setFieldTouched(name);
        setFieldValue(name, e.map((d: any) => d.value));
    };
    return (
        <div className='form-group mb-3' >
            <label htmlFor={id} className={`mb-1 ${isError && 'is-invalid'}`} >{labelName}
                {isRequired && <sup>*</sup>}
            </label>
            <SelectWithCheckboxes
                placeholder={placeholder}
                options={options}
                value={value?.value}
                onChange={change}
                {...props}
            />
            {hasError && <i className='is-invalid'>{errors[name]}</i>}

        </div>
    );
};
export default AppFormMultiSelect;
