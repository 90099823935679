import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAxios } from 'services/requestService';
import { clientsHaveActivatedServices } from 'utils/utils';
import useModal from 'components/Modal/hook/useModal';
import Button from 'components/Button/Button';
import useTable from 'components/Table/hook/useTable';
import { Client } from 'interfaces/client';

/*
 Button component to delete one or multiple client(s)
*/

interface IDeleteClient {
    clients: Client[],
}

const DeleteTableClient = ({ clients }: IDeleteClient) => {
    const { showAlert } = useModal();
    const { selected, handleClearAllCheckBoxes, toggleRefetch } = useTable();
    const { t } = useTranslation();
    const { delData } = useAxios();

    const handleDeleteClient = () => {
        delData('client', {
            params: {
                uuid: selected
            }
        }).then(_ => {
            toggleRefetch();
        }).catch(_ => {
            showAlert({
                content: t('common.modal.error.internalError.content')
            });
        });
    };

    const handleModalOpenDeleteClient = () => {
        const clientWithServices = clientsHaveActivatedServices(clients, selected);
        if (clientWithServices) {
            showAlert({
                content: t('clientManagement.modal.clientWithServices', { client: clientWithServices }),
                onConfirm: () => handleClearAllCheckBoxes()
            });
        } else {
            showAlert({
                content: t('clientManagement.modal.confirmDelete', { count: selected?.length }),
                onConfirm: () => handleDeleteClient(),
                showCancel: true
            });
        }
    };

    return (
        <Button
            title={t('clientManagement.button.delete')}
            className='btn-cta'
            buttonType='delete'
            disabled={selected?.length === 0}
            onClick={handleModalOpenDeleteClient}
        />
    );
};

export default DeleteTableClient;
