import useTable from 'components/Table/hook/useTable';
import { BlockListActionEnum, BlockListFilters, BlockListSort, PaginationInput, blockListContext } from 'context/blockListContext';
import { BlockList } from 'interfaces/blockList';
import { PaginationResponse } from 'interfaces/pagination';
import { useContext, useEffect } from 'react';

const useBlockList = () => {
    const { state, dispatch } = useContext(blockListContext);
    const { sortConfig, pageMeta, toggleRefetch } = useTable();

    useEffect(() => {
        setSort(sortConfig);
        setPaginationInput(pageMeta);
    }, [sortConfig, pageMeta?.currentPage, pageMeta?.elementsPerPage]);

    const setLoading = (isLoading: boolean) => {
        dispatch({ type: BlockListActionEnum.SET_LOADING, payload: isLoading });
    };

    const setData = (response: PaginationResponse<BlockList[]>) => {
        dispatch({ type: BlockListActionEnum.SET_DATA, payload: response });
    };

    const setIsError = (isError: boolean) => {
        dispatch({ type: BlockListActionEnum.SET_IS_ERROR, payload: isError });
    };

    const setError = (e: unknown) => {
        dispatch({ type: BlockListActionEnum.SET_ERROR, payload: e });
    };

    const setSort = (sort: BlockListSort) => {
        dispatch({ type: BlockListActionEnum.SET_SORT, payload: sort });
    };

    const setPaginationInput = (pagination: PaginationInput) => {
        dispatch({ type: BlockListActionEnum.SET_PAGINATION_INPUT, payload: pagination });
    };

    const setFilters = (filters: BlockListFilters) => {
        dispatch({ type: BlockListActionEnum.SET_FILTERS, payload: filters });
    };

    return {
        state,
        setLoading,
        setData,
        setIsError,
        setError,
        setFilters,
        toggleRefetch,
    };
};

export default useBlockList;
