import React, { useEffect, useState } from "react";
import "assets/scss/clientList.scss";
import { ReactComponent as SvgArrow } from "assets/icons/icon-collapse-arrow.svg";

type Props = {
  title: string;
  open?: boolean;
  childrenRef: React.MutableRefObject<null>;
  className: string;
};

const Collapse: React.FC<Props> = ({
  children,
  title,
  open,
  childrenRef,
  className,
}) => {
  const [show, setShow] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const [height, setHeight] = useState<undefined | number | string>(0);
  const [overflow, setOverflow] = useState("hidden");

  useEffect(() => {
    if (open) {
      handleOpen();
    }
  }, [open]);

  const handleOpen = () => {
    // @ts-ignore
    const componentHeight = childrenRef?.current?.scrollHeight;
    if (!show) {
      setContentHeight(componentHeight);
    } else {
      setOverflow("hidden");
      setContentHeight(0);
      setHeight(undefined);
    }
    setShow(!show);
  };

  const handleTransitionEnd = () => {
    if (show) {
      setHeight("inherit");
      setOverflow("inherit");
    } else {
      setHeight(0);
    }
  };

  return (
    <div className={`client-list-header-collapse-container ${className}`}>
      <div className={`client-list-header-collapse ${show ? "is-open" : ""}`}>
        <div
          className="client-list-header-collapse-header d-flex justify-content-between align-items-center"
          onClick={handleOpen}
        >
          <h2 className="collapse-header-title mb-2">{title}</h2>
          <SvgArrow
            className={`collapse-arrow-button ${show ? "active" : ""}`}
          />
        </div>
        <div
          onTransitionEnd={handleTransitionEnd}
          className="client-list-header-content"
          style={{
            height,
            minHeight: `${contentHeight + (show ? 10 : 0)}px`,
            transition: "min-height 300ms ease-in-out",
            overflow,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Collapse;
