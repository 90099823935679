import React, { CSSProperties } from "react";
import { TFunction } from "i18next";
import moment from "moment";
import { capitalize, getActivedServiceLabel, getPlanLabel, getServicesTypes } from "utils/utils";
import DisplayServices from "components/DisplayServices/DisplayServices";
import DownloadButton from "pages/client/components/DownloadButton";
import SendButton from "pages/client/components/SendButton";
import { BlockList } from "interfaces/blockList";
import { Client } from "interfaces/client";

type ColumnDefinitionType = {
	key: string;
	header?: string;
	type?: string;
	style?: CSSProperties;
	align?: "center" | "left" | "right" | "justify" | "char" | undefined;
};

export const formatClientTable = (t: TFunction, data: Client[]) => {
	const head: Array<ColumnDefinitionType> = [
		{
			key: "name",
			header: t("clientManagement.table.client"),
			type: "button-sortable",
			style: {
				maxWidth: "15%",
			},
		},
		{
			key: "mail",
			header: t("clientManagement.table.email"),
			type: "button-sortable",
			style: {
				maxWidth: "20%",
			},
		},
		{
			key: "date",
			header: t("clientManagement.table.creationDate"),
			type: "button-sortable",
			style: {
				maxWidth: "15%",
			},
		},
		{
			key: "activatedServices",
			header: t("clientManagement.table.activatedServices"),
			type: "text",
			style: {
				maxWidth: "20%",
			},
		},
		{
			key: "origin",
			header: t("clientManagement.table.origin"),
			type: "text",
			style: {
				maxWidth: "10%",
			},
		},
		{
			key: "subOrigin",
			header: t("clientManagement.table.subOrigin"),
			type: "text",
			style: {
				maxWidth: "10%",
			},
		},
		{
			key: "plan",
			header: t("clientManagement.table.plan"),
			type: "text",
			style: {
				maxWidth: "10%",
			},
		},
		{
			key: "checkbox",
			type: "checkbox",
			style: {
				maxWidth: "10%",
			},
		},
	];

	const body = data.map((element) => {
		const services = getServicesTypes(element);
		const linkTo = `/client-management/${element.uuid}/account`;
		return {
			name: {
				type: "link",
				value: element?.name,
				linkTo,
				style: {
					textOverflow: "ellipsis",
					width: "100%",
					display: "block",
					overflow: "hidden",
					transform: "translateY(30%)",
				},
			},
			mail: {
				type: "link",
				value: element?.mail,
				linkTo,
				style: {
					textOverflow: "ellipsis",
					width: "100%",
					display: "block",
					overflow: "hidden",
					transform: "translateY(30%)",
				},
			},
			date: {
				type: "link",
				value: moment(element.date.created).format("DD/MM/YYYY"),
				linkTo,
			},
			activatedServices: {
				type: "link",
				value: <DisplayServices services={services.map(service => getActivedServiceLabel(t, service))} />,
				linkTo,
			},
			origin: {
				type: "link",
				value: element.inscriptionOrigin
					? t(`clientManagement.origin.${element.inscriptionOrigin}`)
					: "",
				linkTo,
				style: {
					textOverflow: "ellipsis",
					width: "100%",
					display: "block",
					overflow: "hidden",
					transform: "translateY(30%)",
				},
			},
			subOrigin: {
				type: "link",
				value: element.inscriptionSubOrigin
					? capitalize(element.inscriptionSubOrigin)
					: "",
				linkTo,
			},
			plan: {
				value: getPlanLabel(t,
					element.clientSupport && element.clientSupport[0]
						? element?.clientSupport[0].support.name
						: "TRIAL"),
				type: "text",
			},
			checkbox: {
				type: "checkbox",
				value: element.uuid,
			},
		};
	});

	return { head, body };
};

export const formatInvoicesTable = (t: TFunction, data: any) => {
	const head: Array<ColumnDefinitionType> = [
		{
			key: "year",
			header: t("clientInvoice.table.head.year"),
			type: "text",
			style: {
				maxWidth: "15%",
			},
		},
		{
			key: "month",
			header: t("clientInvoice.table.head.month"),
			type: "text",
			style: {
				maxWidth: "15%",
			},
		},
		{
			key: "invoice",
			header: t("clientInvoice.table.head.invoice"),
			type: "text",
			style: {
				maxWidth: "55%",
			},
		},
		{
			key: "download",
			type: "text",
			style: {
				maxWidth: "5%",
			},
		},
		{
			key: "send",
			type: "text",
			style: {
				maxWidth: "5%",
			},
		},
		{
			key: "checkbox",
			type: "checkbox",
			style: {
				maxWidth: "5%",
			},
		},
	];

	const body = data?.map((element: any) => {
		return {
			year: {
				type: "text",
				value: moment(element?.invoiceDate).year(),
			},
			month: {
				type: "text",
				value: moment(element?.invoiceDate)
					.month(element?.invoiceDate)
					.format("MMMM"),
			},
			invoice: {
				type: "text",
				value:
					element.originalName ||
					`${element.client.name}-${
						element.invoiceDate
					}.${element.fileName.split(".").pop()}`,
			},
			download: {
				type: "text",
				value: (
					<DownloadButton
						url={`${process.env.REACT_APP_URL_API}/invoice/${element?.uuid}`}
						filename={
							element?.originalName ||
							`${element?.invoiceDate}.${element?.fileName
								?.split(".")
								.pop()}`
						}
					/>
				),
				align: "right",
			},
			send: {
				type: "text",
				value: (
					<SendButton
						invoiceUuid={element?.uuid}
						client={element?.client?.mail}
					/>
				),
				align: "right",
			},
			checkbox: {
				type: "checkbox",
				value: element.uuid,
			},
		};
	});

	return { head, body };
};

export const formatSpamListTable = (t: TFunction, data: BlockList[]) => {
	const head: Array<ColumnDefinitionType> = [
		{
			key: "domain",
			type: "button-sortable",
			header: t("spam-list.table.head.domain"),
			style: {
				maxWidth: "5%",
			},
		},
		{
			key: "created_at",
			type: "button-sortable",
			header: t("spam-list.table.head.created-at"),
			style: {
				maxWidth: "5%",
			},
		},
		{
			key: "checkbox",
			type: "checkbox",
			style: {
				maxWidth: "5%",
			},
		},
	];

	const body = data?.map((element) => {
		return {
			domain: {
				type: "text",
				value: element.domain,
			},
			created_at: {
				type: "text",
				value: moment(element.created_at).format("YYYY-MM-DD"),
			},
			checkbox: {
				type: "checkbox",
				value: element.uuid,
			},
		};
	});

	return { head, body };
};
