
import 'assets/scss/optIn.scss';
import Export from './components/Export';

const OptInExport = () => {
    return (
        <div className="optin-export-container d-flex flex-column">
          <div className="client-management-export d-flex mb-3 justify-content-between align-items-center">
            <Export />
          </div>      
        </div>
      );
};

export default OptInExport;
