/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useAxios } from "services/requestService";
import { Option as OptionType } from "interfaces/option";
import "../../../assets/scss/clientList.scss";
import Collapse from "components/Collapase/Collapse";
import AppForm from "components/Form/AppForm";
import { Field, useFormikContext } from "formik";
import Button from "components/Button/Button";
import useClientManagement from "hooks/useClientManagent";
import { useTranslation } from "react-i18next";
import { ActionMeta } from "react-select";
import CustomAsyncSelect from "components/Select/AsyncSelect";
import { ClientManagementFilter } from "context/clientManagementContext";
import AppFormMultiSelect from "components/Form/AppFormMultiSelect";
import { ActivedServices } from "interfaces/client";

interface FormFilterProps {
  supportsOptions: OptionType[] | undefined;
  activedServicesOptions: OptionType[] | undefined;
  asyncSelect: {
    loadFilterOptions(
      filterKey: string,
      inputValue: string,
      callback: (options: Array<OptionType>) => void
    ): Promise<void>;
    onFilterInputChange(
      filterKey: string,
      newValue: unknown,
      actionMeta: ActionMeta<unknown>
    ): ClientManagementFilter | undefined;
  };
}

const FormFilter: React.FC<FormFilterProps> = ({
  supportsOptions,
  activedServicesOptions,
  asyncSelect: { loadFilterOptions, onFilterInputChange },
}) => {
  const { setValues, values } = useFormikContext();
  const { t } = useTranslation();
  const formikValues = values as Record<string, any>;

  const [key, setKey] = useState(0);

  const clearOnClick = () => {
    handleOnChangeAsyncSelect("", "", {
      action: "clear",
      removedValues: [],
    });
  };

  const handleInputChangeAsyncSelect = (
    filterKey: string,
    value: string
  ): string => {
    if (value !== "") {
      switch (filterKey) {
        case "mail":
          setValues({
            mail: value,
            name: undefined,
          });
          break;
        case "name":
          setValues({
            name: value,
            mail: undefined,
          });
          break;
      }
    }
    return value;
  };

  const handleOnChangeAsyncSelect = (
    filterKey: string,
    newValue: unknown,
    actionMeta: ActionMeta<unknown>
  ) => {
    setValues({
      ...onFilterInputChange(filterKey, newValue, actionMeta),
    });
    setKey((prevKey) => prevKey + 1); // force re-render
    return newValue as string;
  };

  const handleOnChangeMultiSelect = (filterKey: string, newValue: any) => {
    setValues({
      ...onFilterInputChange(filterKey, newValue, {
        action: "select-option",
        option: {},
      }),
    });
    setKey((prevKey) => prevKey + 1); // force re-render
  };

  const getMultiSelectValues = (key: string) => {
    if (formikValues && formikValues[key] && Array.isArray(formikValues[key])) {
      return Object.values(formikValues[key]).map((value) => ({
        label: value,
        value: value,
      }));
    } else {
      return [];
    }
  };

  return (
    <>
      <div className="d-flex flex-row mt-2">
        <div className="col-4 mr-2">
          <Field
            component={AppFormMultiSelect}
            labelName={t("clientManagement.filters.plan-label")}
            name="support"
            options={supportsOptions}
            value={getMultiSelectValues("support")}
            placeholder={t(
              "clientManagement.filters.plan-placeholder"
            ).toLowerCase()}
            onChange={(newValue: any) => {
              handleOnChangeMultiSelect("support", newValue);
            }}
            isSearchable={false}
          />
        </div>
        <div className="col-4 mr-2">
          <Field
            component={AppFormMultiSelect}
            labelName={t("clientManagement.filters.service-active-label")}
            name="activedServices"
            options={activedServicesOptions}
            value={getMultiSelectValues("activedServices")}
            placeholder={t(
              "clientManagement.filters.service-active-placeholder"
            ).toLowerCase()}
            onChange={(newValue: any) => {
              handleOnChangeMultiSelect("activedServices", newValue);
            }}
            isSearchable={false}
          />
        </div>
      </div>
      <div className="d-flex flex-row mt-1">
        <div className="col-4 mr-2">
          <CustomAsyncSelect
            key={key}
            onInputChange={(value): string =>
              handleInputChangeAsyncSelect("mail", value)
            }
            label={t("clientManagement.filters.mail-label")}
            loadOptions={(inputValue, callback) =>
              loadFilterOptions("mail", inputValue, callback)
            }
            onChange={(newValue, actionMeta) =>
              handleOnChangeAsyncSelect("mail", newValue, actionMeta)
            }
            placeholder={t("clientManagement.filters.mail-placeholder")}
            value={formikValues?.mail}
          />
        </div>
        <div className="col-4">
          <CustomAsyncSelect
            key={key}
            onInputChange={(value): string =>
              handleInputChangeAsyncSelect("name", value)
            }
            label={t("clientManagement.filters.name-label")}
            loadOptions={(inputValue, callback) =>
              loadFilterOptions("name", inputValue, callback)
            }
            onChange={(newValue, actionMeta) =>
              handleOnChangeAsyncSelect("name", newValue, actionMeta)
            }
            placeholder={t("clientManagement.filters.name-placeholder")}
            value={formikValues?.name}
          />
        </div>
      </div>

      <div className="d-flex flex-row">
        <div className="col-12 d-flex justify-content-end ms-5 align-items-center">
          <div className="d-flex">
            <Button
              type="button"
              buttonType="delete"
              className="btn-cta"
              title={t("clientManagement.filters.clear")}
              onClick={clearOnClick}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const Filter: React.FC = () => {
  const [supportsOptions, setSupportsOptions] = useState<OptionType[]>();
  const [activedServicesOptions, setActivedServicesOptions] =
    useState<OptionType[]>();
  const { setFilters } = useClientManagement();
  const { getData2 } = useAxios();
  const { t } = useTranslation();
  const componentRef = useRef<any>(null);
  const initialValues: any = {
    name: undefined,
    mail: undefined,
  };

  const loadFilterOptions = async (
    filterKey: string,
    inputValue: string,
    callback: (options: Array<OptionType>) => void
  ) => {
    if (!inputValue || inputValue.length < 3) {
      return;
    }
    const { data } = await getData2<string[]>("admin/clients/filter/options", {
      [filterKey]: inputValue,
    });
    if (data) {
      callback(
        data.map((item) => ({ value: item, label: item } as OptionType))
      );
    }
  };

  useEffect(() => {
    (async () => {
      // get supports available
      const { data: dataSupports } = await getData2<any>("admin/supports");
      const supportsOptions = dataSupports.map((support: any) => ({
        label: support.name.toLowerCase(),
        value: support.name,
      }));
      supportsOptions.push({ label: "Trial", value: "Trial" });
      setSupportsOptions(supportsOptions);

      // get actived services available
      const activedServicesOptions: OptionType[] = Object.entries(
        ActivedServices
      ).map(([value]) => ({
        value: value,
        label: value.toLowerCase().replace(/_/g, " "),
      }));

      setActivedServicesOptions(activedServicesOptions);
    })();
  }, []);

  const onFilterInputChange = (
    filterKey: string,
    newValue: unknown,
    actionMeta: ActionMeta<unknown>
  ): ClientManagementFilter | undefined => {    
    switch (actionMeta.action) {
      case "clear":
        setFilters(initialValues);
        return initialValues;
      case "select-option":
        let filterValue: ClientManagementFilter = {};
        let value;
        switch (filterKey) {
          case "mail":
          case "name":
            value = (newValue as { value: string }).value;
            break;
          case "support":
          case "activedServices":
            value = (newValue as Array<{ value: string }>).map(
              (item) => item.value
            );
            break;
        }
        filterValue = { [filterKey]: value };
        setFilters(filterValue);
        return filterValue;
    }
  };

  return (
    <div className="flex-grow-1">
      <Collapse
        title={t("clientManagement.filters.title")}
        childrenRef={componentRef}
        className="flex-start"
      >
        <div ref={componentRef}>
          <AppForm
            enableReinitialize
            initialValues={initialValues}
            onSubmit={() => {}}
          >
            <FormFilter
              supportsOptions={supportsOptions}
              activedServicesOptions={activedServicesOptions}
              asyncSelect={{
                loadFilterOptions,
                onFilterInputChange,
              }}
            />
          </AppForm>
        </div>
      </Collapse>
    </div>
  );
};

export default Filter;
