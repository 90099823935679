import 'assets/scss/displayServices.scss';

/*
 Displays each activated service type
*/

interface IServices {
    services: string[]
};

const DisplayServices = ({
    services
}: IServices) => {
    if (services.length === 0) {
        return null;
    }

    return (
        <>
            {
                services.filter((service: string) => service.length > 0).map((service, idx) => <span key={idx} className='service'>{service}</span>)
            }
        </>
    );
};

export default DisplayServices;
