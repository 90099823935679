import React, { CSSProperties } from 'react';
import { ReactComponent as RoundLoaderSvg } from 'assets/loader/round-loader.svg';

interface ILoader {
    isLoading?: boolean
    size?: number;
    style?: CSSProperties
}

const RoundedLoader = ({ isLoading = false, size = 26 }: ILoader) => (
    isLoading
        ? <RoundLoaderSvg height={`${size}px`} width={`${size}px`} />
        : null
);

export default RoundedLoader;
