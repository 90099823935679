import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import useTable from 'components/Table/hook/useTable';
import Checkbox from 'components/Checkbox/Checkbox';
import Pagination from 'components/Pagination/Pagination';
import 'assets/scss/table.scss';
import { ReactComponent as ArrowTableSvg } from 'assets/icons/icon-arrow-table.svg';

type ColumnDefinitionType = {
    key: string;
    header?: string;
    type?: string;
    style?: CSSProperties;
    align?: 'center' | 'left' | 'right' | 'justify' | 'char' | undefined;
}

type TableProps = {
    stickyHead?: boolean
    stickyTop?: string
};

const Table: React.FC<TableProps> = ({
    stickyHead = false,
    stickyTop
}) => {
    const {
        head,
        body,
        selected,
        sortConfig,
        handleAllCheckbox,
        handleCheckbox,
        getChecked,
        handleSortConfig
    } = useTable();

    const getClassNamesFor = (name: string) => {
        return sortConfig?.key === name ? `active btn-table-arrow ${sortConfig?.direction?.toLowerCase()}` : undefined;
    };

    const getAllCheckboxesValue = () => {
        return body?.map((element: any) => element?.checkbox?.value);
    };
    const columns = head?.map((column: ColumnDefinitionType, index: number) => {
        const style = column?.style ?? {
            maxWidth: '20%'
        };

        return (
            <th
                align={column?.align || 'left'}
                key={`headCell-${index}`}
                style={{ ...style, width: '100%' }}
                className='px-3'
            >
                {column.type === 'text' &&
                    <>
                        {column.header}
                    </>
                }
                {(column.type === 'button-sortable') &&
                    <>
                        <button
                            type="button"
                            name={column.key}
                            onClick={() => handleSortConfig(column.key)}
                            className={getClassNamesFor(column.key)}
                        >
                            {column.header}
                            <ArrowTableSvg fill="#702D6A" />
                        </button>
                    </>
                }
                {
                    column.type === 'checkbox' &&
                    <div className='d-flex justify-content-end'>
                        <Checkbox
                            onClick={() => handleAllCheckbox(getAllCheckboxesValue())}
                            isChecked={
                                !!((getAllCheckboxesValue()?.length === selected?.length &&
                                    selected?.length > 0))
                            }
                            value={column.key}
                        />
                    </div>
                }
            </th>
        );
    });

    const rows = body?.map((row: any, index: number) => {
        return (
            <tr key={`row-${index}`}>
                {
                    Object.keys(row).map((data, index2) => {
                        const cell = row[data];

                        return (
                            <td key={`cell-${index2}`} className='px-3' align={cell?.align || 'left'}>
                                {
                                    cell.type === 'text' &&
                                    <>
                                        {cell.value || ''}
                                    </>

                                }
                                {
                                    cell.type === 'link' &&
                                    <div className='table-cell' title={cell.value}>
                                        <Link style={{ ...cell?.style }} to={cell.linkTo} className="table-link px-3">
                                            {cell.value || ''}
                                        </Link>
                                    </div>
                                }
                                {
                                    cell.type === 'checkbox' &&
                                    <div className='d-flex justify-content-end'>
                                        <Checkbox
                                            isChecked={getChecked(cell.value)}
                                            onClick={() => handleCheckbox(cell.value)}
                                            value={cell.value}
                                        />
                                    </div>
                                }
                            </td>
                        );
                    })
                }
            </tr>
        );
    });

    return (
        <>
            <table className='table'>
                <thead id='table-head'>
                    <tr>{columns}</tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
            <Pagination />
        </>
    );
};

export default Table;
