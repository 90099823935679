import React from 'react';
import Select, { Props, components, StylesConfig } from 'react-select';
import { Option as OptionType } from 'interfaces/option';

interface ISelect {
    options: Array<OptionType> | undefined
    placeholder?: string,
    isDisabled?: boolean
}

export const customStyle: StylesConfig = {
    control: (base, state) => ({
        ...base,
        border: state.isFocused ? '1px solid #6b7897' : '1px solid #E1E3EA',
        boxShadow: state.isFocused ? '1px solid #6b7897' : '1px solid #E1E3EA',
        fontWeight: 200,
        fontSize: '14px',
        textTransform: 'capitalize',
        '&:hover': {
            border: state.isFocused ? '1px solid #6b7897' : '1px solid #E1E3EA'
        }
    }),
    option: (base, state) => ({
        ...base,
        color: '#6b7897',
        fontWeight: 200,
        fontSize: '14px',
        textTransform: 'capitalize',
        backgroundColor:
            state.isSelected
                ? 'rgba(107, 120, 151, 0.8)'
                : state.isFocused
                    ? 'rgba(107, 120, 151, 0.2)'
                    : 'none',
        ':active': {
            ...base[':active'],
            backgroundColor: state.isSelected ? 'rgba(107, 120, 151, 0.8)' : 'none'
        }
    }),
    singleValue: (base, state) => ({
        ...base,
        fontSize: '14px',
        fontWeight: 200,
        textTransform: 'capitalize'
    }),
    placeholder: (base, state) => ({
        ...base,
        fontSize: '14px',
        fontWeight: 200,
        textTransform: 'capitalize'
    })
};

const optionStyle = {
    height: '15px',
    width: '15px',
    border: '1px solid #DEE2E7',
    borderRadius: '2px',
    backgroundColor: 'white',
    padding: '3px'
};

const Option = (props: any) => {
    return (
        <div>
            <components.Option {...props} >
                <div className='d-flex align-items-center'>
                    <div style={optionStyle} >
                        <div style={{ borderRadius: '50%', backgroundColor: props.isSelected ? 'rgba(112, 45, 106, 0.2)' : 'white', height: '100%' }} />
                    </div>
                    <label className={`pl-1 ${props.isSelected && 'color-white'}`}>{props.label}</label>
                </div>
            </components.Option>
        </div>
    );
};

const MultiValue = (props: any) => (
    <components.MultiValue {...props} >
        <span>{props.data.label}</span>
    </components.MultiValue>
);

const SelectWithCheckboxes = ({
    onChange,
    options,
    value,
    placeholder,
    isDisabled = false,
    ...props
}: ISelect & Props) => {
    return (
        <Select
            styles={customStyle}
            isDisabled={isDisabled}
            options={options}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option, MultiValue }}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            {...props}
        />
    );
};
export default SelectWithCheckboxes;
