import React from 'react';

interface ICheckbox {
    isChecked?: boolean,
    onClick?: () => void,
    customClassName?: string,
    value: string
}

const Checkbox = ({
    isChecked = false,
    onClick,
    value,
    customClassName = '',
    ...props
}: ICheckbox) => {
    return (
        <input className={customClassName} checked={isChecked} type='checkbox' onClick={onClick} onChange={onClick} value={value} {...props} />
    );
};

export default Checkbox;
