import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAxios } from 'services/requestService';
import useModal from 'components/Modal/hook/useModal';
import Button from 'components/Button/Button';
import useTable from 'components/Table/hook/useTable';
import AddBlockList, { AddSpamValues } from './AddBlockList';
import 'assets/scss/blockList.scss'

/*
 Buttons actions for BlockList
*/

interface BlockListActionsProps {
    submitAddForm: (values: AddSpamValues) => void
}

const BlockListActions: React.FC<BlockListActionsProps> = ({ submitAddForm }) => {
    const { showAlert } = useModal();
    const { selected, toggleRefetch } = useTable();
    const { t } = useTranslation();
    const { delData } = useAxios();

    const handleDeleteSpams = async () => {
        try {
            await delData('admin/block-list', {
                data: {
                    uuids: selected
                }
            })
            toggleRefetch();
        } catch (e) {
            console.error(e)
            showAlert({
                content: t('common.modal.error.internalError.content')
            });
        }
    };

    const handleModalOpenDeleteSpams = () => {
        showAlert({
            title: t('spam-list.modal.delete-title'),
            content: t('spam-list.modal.delete-label'),
            onConfirm: handleDeleteSpams,
            showCancel: true,
            confirmBtnText: t('spam-list.buttons.delete')
        });
    };

    const handleModalOpenAddSpam = () => {
        showAlert({
            title: t('spam-list.modal.add-title', { count: selected?.length }),
            content: <AddBlockList submitAddForm={submitAddForm} />,
            showCancel: false,
            showConfirm: false
        });
    };

    return (
        <div className='block-list-actions'>
            <Button
                title={t('spam-list.buttons.delete')}
                className='btn-cta'
                buttonType='delete'
                disabled={selected?.length === 0}
                onClick={handleModalOpenDeleteSpams}
            />
            <Button
                title={t('spam-list.buttons.add')}
                className='btn-main'
                buttonType='add'
                onClick={handleModalOpenAddSpam}
            />
        </div>
    );
};

export default BlockListActions;
