import useTable from 'components/Table/hook/useTable';
import { clientManagementContext, ClientManagementFilter } from 'context/clientManagementContext';
import { Client } from 'interfaces/client';
import { PaginationResponse } from 'interfaces/pagination';
import { useContext, useEffect } from 'react';

const useClientManagement = () => {
    const { state, dispatch } = useContext(clientManagementContext);
    const { sortConfig, pageMeta, toggleRefetch } = useTable();

    useEffect(() => {
        setSort(sortConfig);
        setPaginationInput(pageMeta);
    }, [sortConfig, pageMeta?.currentPage, pageMeta?.elementsPerPage]);

    const setLoading = (isLoading: boolean) => {
        dispatch({ type: 'SET_LOADING', target: isLoading });
    };

    const setClientResponse = (response: PaginationResponse<Client[]>) => {
        dispatch({ type: 'SET_RESPONSE_CLIENT', target: response });
    };

    const setServicesResponse = (response: any) => {
        dispatch({ type: 'SET_SERVICE_RESPONSE', target: response });
    };

    const setIsError = (isError: boolean) => {
        dispatch({ type: 'SET_IS_ERROR', target: isError });
    };

    const setError = (e: unknown) => {
        dispatch({ type: 'SET_ERROR', target: e });
    };

    const setSort = (sort: any) => {
        dispatch({ type: 'SET_SORT', target: sort });
    };

    const setPaginationInput = (pagination: any) => {
        dispatch({ type: 'SET_PAGINATION_INPUT', target: pagination });
    };

    const setFilters = (filters: ClientManagementFilter) => {
        dispatch({ type: 'SET_FILTERS', target: filters });
    };

    return {
        state,
        setLoading,
        setClientResponse,
        setServicesResponse,
        setIsError,
        setError,
        setFilters,
        toggleRefetch,
    };
};

export default useClientManagement;
