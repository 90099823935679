/* eslint-disable no-unused-vars */
import React, { useRef } from "react";
import "../../../assets/scss/clientList.scss";
import AppForm from "components/Form/AppForm";
import AppFormDatePicker from "components/Form/AppFormDatePicker";
import { Field, useFormikContext } from "formik";
import moment from "moment";
import Button from "components/Button/Button";
import { sleep } from "utils/utils";
import { useTranslation } from "react-i18next";
import { useToast } from "hooks/useToast";
import { ReactComponent as SearchSvg } from "assets/icons/toast/icon-search.svg";
import { ReactComponent as DownloadSvg } from "assets/icons/toast/icon-download.svg";
import { ReactComponent as CheckSvg } from "assets/icons/toast/icon-check.svg";
import { authHeader } from "services/API";
import Axios from "axios";

import FileSaver from "file-saver";

interface FormExportProps {
  submitCsvRequest: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    values: any
  ) => void;
}

const FormExport: React.FC<FormExportProps> = ({  
  submitCsvRequest,
}) => {
  const { initialValues, setValues, values } = useFormikContext();
  const { t } = useTranslation();
  const formikValues = values as Record<string, any>;

  const reset = () => {
    setValues(initialValues);
  };

  const canSubmitCsvRequest = () => {
    return formikValues.startingDate !== "";
  };

  return (
    <>      
      <div className="d-flex flex-row">
        <div className="col-4 mr-2">
          <Field
            label={t("optIn.export.created-after-label")}
            component={AppFormDatePicker}
            name="startingDate"
            max={moment().format("YYYY-MM-DD")}
          />
        </div>        
      </div>      
      <div className="d-flex flex-row">
        <div className="col-12 d-flex justify-content-end ms-5 align-items-center">
          <div className="d-flex">
            <Button
              type="button"
              buttonType="delete"
              className="btn-cta"
              title="Clear"
              onClick={reset}
            />
            <Button
              disabled={!canSubmitCsvRequest()}
              type="button"
              buttonType="export"
              className="btn-main ml-2"
              title="Export Csv"
              onClick={(e) => submitCsvRequest(e, formikValues)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const Export: React.FC = () => {
  const { t } = useTranslation();
  const componentRef = useRef<any>(null);
  const initialValues: any = {
    period: "",
    month: "",
    year: "",
    support: [],
    startingDate: "",
    endingDate: "",
  };
  const { addToast, updateToast, removeToast } = useToast(); 

  const submitCsvRequest = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    values: any
  ) => {
    const valuesCopy: any = { ...values };
    Object.keys(valuesCopy).forEach((key) =>
      valuesCopy[key] === undefined || valuesCopy[key] === ""
        ? delete valuesCopy[key]
        : {}
    );
    const params: any = { ...valuesCopy };
    
    const id = addToast(() => (
      <>
        <SearchSvg height={100} />
        <span className="pl-1">
          {t("optIn.export.toast.exporting")}
        </span>
      </>
    ));
    await sleep(500);
    const url = "admin/clients/optin/csv";
    Axios.get(url, {
      responseType: "blob",
      headers: authHeader(),
      params,
      onDownloadProgress: () => {
        updateToast(id, () => (
          <>
            <DownloadSvg height={100} />
            <span className="pl-1">
              {t("optIn.export.toast.downloading")}
            </span>
          </>
        ));
      },
    }).then(async (data) => {
      await sleep(500);
      FileSaver.saveAs(
        data.data,
        `user_optin_${moment().format("YYYY_MM_DD_HH_mm_ss_Z")}.csv`
      );
      updateToast(id, () => (
        <>
          <CheckSvg height={40} />
          <span className="pl-1">
            {t("optIn.export.toast.success")}
          </span>
        </>
      ));
      await sleep(500);
      removeToast(id);
    });
  };

  return (
    <div className="optin-form-export">
      <div ref={componentRef} className="optin-form-export-container">
        <AppForm
          enableReinitialize
          initialValues={initialValues}
          onSubmit={() => {}}
        >
          <FormExport
            submitCsvRequest={submitCsvRequest}
          />
        </AppForm>
      </div>
    </div>
  );
};

export default Export;
