import React, { useContext } from 'react';
import AppForm from 'components/Form/AppForm';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import AppFormSubmitButton from 'components/Form/AppFormSubmitButton';
import { loadingContext } from 'components/Loader/context/loaderContext';
import RoundedLoader from 'components/Loader/RoundedLoader';
import AppFormField from 'components/Form/AppFormField';
import Button from 'components/Button/Button';
import useModal from 'components/Modal/hook/useModal';

const AddBlockListForm: React.FC = () => {
    const { t } = useTranslation();
    const loading = useContext(loadingContext);
    const {  clearAlert } = useModal();

    return (
        <>
            <Field
                labelName={t('spam-list.modal.add-label')}
                component={AppFormField}
                name="domain"
                id="domain"
                prefix="@"
                autoFocus
            />
            <>
                <RoundedLoader isLoading={loading.isLoading} />
                <Button className="btn-cta mr-1" title='Cancel' onClick={clearAlert}/>
                <AppFormSubmitButton requireModification={true} title={t('dashboard.filters.confirm')} className="btn-main" />
            </>
        </>
    );
};

export interface AddSpamValues {
    domain?: string
}

interface AddBlockListProps {
    submitAddForm: (values: AddSpamValues) => void
}

const AddBlockList: React.FC<AddBlockListProps> = ({ submitAddForm }) => {
    const initialValues: AddSpamValues = {
        domain: undefined
    }

    return (
        <AppForm enableReinitialize initialValues={initialValues} onSubmit={(values) => submitAddForm(values as AddSpamValues)}>
            <AddBlockListForm />
        </AppForm>
    );
};

export default AddBlockList;
