import React, { useContext, useEffect, useRef, useState } from 'react';
import { titleContext } from 'components/Title/context/titleContext';
import useTable from 'components/Table/hook/useTable';
import { formatSpamListTable } from 'components/Table/service/tableService';
import { PaginationResponse } from 'interfaces/pagination';
import { useTranslation } from 'react-i18next';
import { useAxios } from 'services/requestService';
import Table from 'components/Table/Table';
import useGetElementByIdInDom from 'hooks/useGetElementByIdInDom';
import { BlockList } from 'interfaces/blockList';
import useBlockList from 'hooks/useBlockList';
import BlockListActions from './components/BlockListActions';
import { AddSpamValues } from './components/AddBlockList';
import useModal from 'components/Modal/hook/useModal';
import axios from 'axios';

const SpamListConfigure = () => {
    const { updateTitle } = useContext(titleContext);
    const isMounted = useRef(false);
    const tableHead = useGetElementByIdInDom('table-head');
    const pageHeader = useGetElementByIdInDom('header');
    const { t } = useTranslation();
    const { initTable, handleTotalPage, sortConfig, pageMeta, refetch } = useTable();
    const { getData2 } = useAxios();
    const [windowOffsetY, setWindowOffsetY] = useState(0);
    const { state, setData, setLoading, setIsError, setError } = useBlockList();
    const blockListParams = {
        sortBy: sortConfig?.key,
        sortOrder: sortConfig?.direction,
        page: pageMeta?.currentPage,
        elementsPerPage: pageMeta?.elementsPerPage,
        ...state.filter
    };
    const { showAlert, clearAlert } = useModal();
    const { postData } = useAxios();
    const { toggleRefetch } = useTable();

    const submitForm = async (values: AddSpamValues) => {
        try {
            await postData('admin/block-list', values, true)
            toggleRefetch();
            clearAlert();
        } catch (e) {
            console.error(e);
            let message = t('common.modal.error.internalError.content');
            if (axios.isAxiosError(e)) {
                message = e.response?.data.message
            }
            clearAlert();
            showAlert({
                content: message,
                title: t('common.modal.error.internalError.title')
            });
        }
    };

    useEffect(() => {
        updateTitle(null, `${t("header.spam-list")}`);
        const handleScroll = () => {
            setWindowOffsetY(window.pageYOffset);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        isMounted.current = true;
        (async () => {
            try {
                setLoading(true);
                const { data } = await getData2<PaginationResponse<BlockList[]>>('admin/block-list', blockListParams);
                if (isMounted.current && data) {
                    setData(data);
                    handleTotalPage(data.totalPage);
                    initTable(formatSpamListTable(t, data.elements));
                }
            } catch (e: unknown) {
                setIsError(true);
                setError(e);
            } finally {
                setLoading(false);
            }
        })();
        return () => {
            isMounted.current = false;
        };
    }, [sortConfig, pageMeta?.currentPage, pageMeta?.elementsPerPage, refetch]);

    useEffect(() => {
        const handleScroll = () => {
            setWindowOffsetY(window.pageYOffset);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <div className='d-flex mb-4 w-100 justify-content-end'>
                <BlockListActions submitAddForm={submitForm} />
            </div>
            <div className="middle-container">
                <Table
                    stickyHead={windowOffsetY && tableHead && tableHead?.top <= windowOffsetY ? true : false}
                    stickyTop={`${pageHeader?.height}px`}
                />
            </div>
        </>

    );
};

export default SpamListConfigure;
