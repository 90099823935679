import React, { useContext } from 'react';
import { tableContext } from 'components/Table/context/tableContext';

/*
 Hook using react context "tableContext" to manipulate table data
*/

const useTable = () => {
    const { state, dispatch } = useContext<any>(tableContext);

    const initTable = ({ head, body }: any) => {
        dispatch({ type: 'INIT_TABLE', payload: { head, body } });
    };

    const handleClearAllCheckBoxes = () => {
        dispatch({ type: 'HANDLE_SELECTED', payload: [] });
    };

    const handleAllCheckbox = (elements: any) => {
        const { selected } = state;
        if (selected.length === elements.length) {
            dispatch({ type: 'HANDLE_SELECTED', payload: [] });
        } else {
            dispatch({ type: 'HANDLE_SELECTED', payload: elements });
        }
    };

    const handleCheckbox = (id: string) => {
        const { selected } = state;
        const index = selected.indexOf(id);
        const tmp = [...selected];
        if (index !== -1) {
            tmp.splice(index, 1);
        } else {
            tmp.push(id);
        }
        dispatch({ type: 'HANDLE_SELECTED', payload: tmp });
    };

    const getChecked = (id: string) => {
        const { selected } = state;
        return (!!selected.includes(id));
    };

    const handleSortConfig = (key: string) => {
        const { sortConfig } = state;
        let direction = 'ASC';

        if (sortConfig?.key === key && sortConfig?.direction === 'ASC') {
            direction = 'DESC';
        }

        dispatch({ type: 'HANDLE_SORT', payload: { key, direction } });
    };

    const previousPage = () => {
        const { pageMeta } = state;

        handleClearAllCheckBoxes();
        dispatch({ type: 'HANDLE_PAGE', payload: { ...pageMeta, currentPage: pageMeta.currentPage - 1 } });
    };

    const nextPage = () => {
        const { pageMeta } = state;

        handleClearAllCheckBoxes();
        dispatch({ type: 'HANDLE_PAGE', payload: { ...pageMeta, currentPage: pageMeta.currentPage + 1 } });
    };

    const handleElementsPerPage = (element: React.ChangeEvent<HTMLSelectElement>) => {
        const { pageMeta } = state;

        handleClearAllCheckBoxes();
        dispatch({ type: 'HANDLE_PAGE', payload: { ...pageMeta, elementsPerPage: parseInt(element?.target?.value) } });
    };

    const handleTotalPage = (total: number) => {
        const { pageMeta } = state;

        handleClearAllCheckBoxes();
        dispatch({ type: 'HANDLE_PAGE', payload: { ...pageMeta, totalPage: total } });
    };

    const toggleRefetch = () => {
        handleClearAllCheckBoxes();
        dispatch({ type: 'HANDLE_REFETCH' });
    };

    return {
        refetch: state.refetch,
        head: state.head,
        body: state.body,
        selected: state.selected,
        sortConfig: state.sortConfig,
        pageMeta: state.pageMeta,
        initTable,
        handleAllCheckbox,
        getChecked,
        handleCheckbox,
        handleClearAllCheckBoxes,
        handleSortConfig,
        previousPage,
        nextPage,
        handleElementsPerPage,
        handleTotalPage,
        toggleRefetch
    };
};

export default useTable;
