import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PaginationSvg } from 'assets/icons/icon-arrow.svg';
import useTable from 'components/Table/hook/useTable';
import 'assets/scss/pagination.scss';
import { loadingContext } from 'components/Loader/context/loaderContext';

const Pagination = () => {
    const { t } = useTranslation();
    const {
        pageMeta,
        handleElementsPerPage,
        previousPage,
        nextPage
    } = useTable();
    // to disable arrow while data is loading
    const { isLoading } = useContext(loadingContext);

    return (
        <div className='pagination d-flex align-items-center justify-content-end'>
            <div>{t('pagination.rowPerPage')}</div>
            <div className='pagination-select'>
                <select name='pages' onChange={handleElementsPerPage}>
                    {[10, 20, 40].map((elementsPerPage, key) => (
                        <option key={key} value={elementsPerPage}>
                            {elementsPerPage}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                {pageMeta?.currentPage} - {pageMeta?.totalPage}
            </div>
            <button
                type='button'
                title='Previous page'
                className='pagination-prev'
                onClick={previousPage}
                disabled={pageMeta?.currentPage === 1 || !pageMeta || isLoading}
            >
                <PaginationSvg fill='#BDBDBD' />
            </button>
            <button
                type='button'
                title='Next page'
                className='pagination-next'
                onClick={nextPage}
                disabled={pageMeta?.currentPage >= pageMeta?.totalPage || isLoading}
            >
                <PaginationSvg fill='#BDBDBD' />
            </button>
        </div>
    );
};

export default Pagination;
