import React, { useCallback, useState } from "react";
import AsyncSelect from "react-select/async";
import { customStyle } from "./Select";
import { Option as OptionType } from "interfaces/option";
import {
  ActionMeta,
  InputActionMeta,
  mergeStyles,
  StylesConfig,
} from "react-select";
import { debounce } from "utils/utils";
import { useTranslation } from "react-i18next";

interface Props {
  id?: string;
  onInputChange(value: string): string;
  loadOptions(
    inputValue: string,
    callback: (options: Array<OptionType>) => void
  ): Promise<void>;
  onChange(newValue: unknown, actionMeta: ActionMeta<unknown>): void;
  style?: StylesConfig;
  placeholder?: string;
  label?: string;
  value?: string;
  loadOptionsMinInputLength?: number;
}

const asyncSelectStyle: StylesConfig = {
  singleValue: (base) => ({
    ...base,
    textTransform: "none",
  }),
  option: (base) => ({
    ...base,
    textTransform: "none",
  }),
  noOptionsMessage: (base) => ({
    ...base,
    textAlign: "left",
  }),
};

const CustomAsyncSelect: React.FC<Props> = ({
  id = "",
  onChange,
  onInputChange,
  loadOptions,
  placeholder = "",
  label = "",
  value,
  loadOptionsMinInputLength = 3,
}) => {
  const { t } = useTranslation();

  let combinedStyles = customStyle;
  combinedStyles = mergeStyles(customStyle, asyncSelectStyle);

  const loadOptionsDebounced = useCallback(
    debounce((inputValue: string, callback: (options: any) => void) => {
      loadOptions(inputValue, callback);
    }, 500),
    []
  );

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    if (actionMeta.action === "input-change") {
      setInputValue(newValue);
    }
    if (onInputChange) {
      onInputChange(newValue);
    }
  };

  let noOptionsMessageLabel = t("clientManagement.filters.select-filter.no-options-message");
  if (inputValue.length == 0) {
    noOptionsMessageLabel = t("clientManagement.filters.select-filter.empty-message");
  } else if (inputValue.length < loadOptionsMinInputLength) {
    noOptionsMessageLabel = t("clientManagement.filters.select-filter.at-least-message", { count: loadOptionsMinInputLength });
  }

  return (
    <div className="form-group mb-3">
      <label className="mb-1" htmlFor={id}>
        {label}
      </label>
      <AsyncSelect
        styles={combinedStyles}
        onInputChange={handleInputChange}
        loadOptions={(inputValue, callback) => {
          if (inputValue.length < loadOptionsMinInputLength) {
            callback([]);
          } else {
            loadOptionsDebounced(inputValue, callback);
          }
        }}
        onChange={onChange}
        placeholder={placeholder}
        value={value ? { label: value, value } : undefined}
        isClearable
        noOptionsMessage={() => noOptionsMessageLabel}
        cacheOptions
      />
    </div>
  );
};

export default CustomAsyncSelect;
