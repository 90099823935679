import React, { ReactNode, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import 'assets/scss/layouts.scss';
import { titleContext } from 'components/Title/context/titleContext';

type OptInLayoutProps = {
    children?: ReactNode
}

const OptInNavLayout: React.FC<OptInLayoutProps> = ({ children }) => {
    const { t } = useTranslation();
    const { updateTitle } = useContext(titleContext);

    useEffect(() => {
        updateTitle(null, `${t("header.opt-in")}`);
    }, []);

    return (
        <>
            <div className='tab-header d-flex align-items-center justify-content-between w-100 mb-4'>
                <div className='tab-container'>
                    <NavLink to="/optin/export" className='tab-button' activeClassName="active" title="Clients">{t('optIn.nav.export')}</NavLink>
                </div>
            </div>
            {children}
        </>
    );
};

export default OptInNavLayout;
