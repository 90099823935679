import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import 'assets/scss/pageLayout.scss';
import logoWis from 'assets/images/img-logo-wis.svg';

const Sidebar = () => {
    const { t } = useTranslation();

    const navBarItems = [
        {
            linkTo: '/dashboard',
            title: t('navbar.dashboard'),
            icon: 'icon-dashboard',
        },
        {
            linkTo: '/client-management',
            title: t('navbar.clientManagement'),
            icon: 'icon-clients',
        },
        {
            linkTo: '/optin',
            title: t('navbar.optin'),
            icon: 'icon-optin',
        },
        {
            linkTo: '/spam-list',
            title: t('navbar.spamList'),
            icon: 'icon-spam',
        },
    ];

    return (
        <div className="sidebar-container">
            <div className="wis-logo-container mb-3">
                <img className="w-100" src={logoWis} alt="logo wis manager" />
            </div>
            <nav className="nav" role="navigation" aria-label="Side bar">
                <ul>
                    {navBarItems.map((item, key) => (
                        <li key={key}>
                            <NavLink
                                key={key}
                                to={item.linkTo}
                                title={item.title}
                                className={item.icon}
                                activeClassName="active"
                            >
                                <span>{item.title}</span>
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
};

export default Sidebar;
