import React from 'react';
import 'assets/scss/blockList.scss'

const SpamListLayout: React.FC = ({ children }) => {
    return (
        <div className='block-list-layout'>
            {children}
        </div>
    );
};

export default SpamListLayout;
