import "assets/scss/clientManagement.scss";
import Export from "./components/Export";

const ClientManagementExport = () => {
  return (
    <div className="client-management-container d-flex flex-column">
      <div className="client-management-export d-flex mb-3 justify-content-between align-items-center">
        <Export />
      </div>      
    </div>
  );
};

export default ClientManagementExport;
