import React, { useContext } from 'react';
import AppForm from 'components/Form/AppForm';
import useDashboardFilter from 'hooks/useDashboardFilter';
import { initialState, State } from 'context/dashboardFilterContext';
import { Field, useFormikContext } from 'formik';
import AppFormDatePicker from 'components/Form/AppFormDatePicker';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import AppFormSubmitButton from 'components/Form/AppFormSubmitButton';
import Button from 'components/Button/Button';
import { loadingContext } from 'components/Loader/context/loaderContext';
import RoundedLoader from 'components/Loader/RoundedLoader';
import { useLocation } from 'react-router-dom';
import { ReactComponent as IconFilter } from '../../../assets/icons/icon-filters-grey.svg';
import 'assets/scss/dashboard.scss';

const FormFilter: React.FC = () => {
    const { t } = useTranslation();
    const { setValues, initialValues, values, dirty, isValid } = useFormikContext();
    const formikValues = values as State;
    const data = useDashboardFilter();
    const loading = useContext(loadingContext);

    const reset = () => {
        setValues(initialValues);
        data.submitForm(initialValues as State);
    };

    return (
        <div className="dashboard-form-filter">
            <div>
                <IconFilter height={'15px'} fill={'#6b7897'} className="mr-2" />
                <span className="form-filter-title">{t('dashboard.filters.date-filters')}</span>
            </div>
            <div className="form-fields">
                <Field
                    label={t('dashboard.filters.created-after-label')}
                    component={AppFormDatePicker}
                    name="startingDate"
                    max={moment().format('YYYY-MM-DD')}
                />
                <Field
                    label={t('dashboard.filters.created-before-label')}
                    component={AppFormDatePicker}
                    name="endingDate"
                    max={moment().format('YYYY-MM-DD')}
                    min={moment(formikValues.startingDate).format('YYYY-MM-DD')}
                />
            </div>
            <div className="form-actions">
                <RoundedLoader isLoading={loading.isLoading} />
                <Button className="btn-cta" title={t('dashboard.filters.reset')} onClick={reset} disabled={!(isValid && dirty)} />
                <AppFormSubmitButton title={t('dashboard.filters.confirm')} className="btn-main" />
            </div>
        </div>
    );
};

const DashboardFilter: React.FC = () => {
    const data = useDashboardFilter();
    const { pathname } = useLocation();

    if (pathname.includes('consumption')) return null;
    return (
        <div className="dashboard-filter">
            <AppForm enableReinitialize initialValues={initialState} onSubmit={data.submitForm}>
                <FormFilter />
            </AppForm>
        </div>
    );
};

export default DashboardFilter;
