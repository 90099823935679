import React from 'react';
import 'assets/scss/optIn.scss';

const OptInLayout: React.FC = ({ children }) => {
    return (
        <div className='optin-layout'>
            {children}
        </div>
    );
};

export default OptInLayout;
