/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useAxios } from "services/requestService";
import { Option as OptionType } from "interfaces/option";
import "../../../assets/scss/clientList.scss";
import AppForm from "components/Form/AppForm";
import AppFormDatePicker from "components/Form/AppFormDatePicker";
import { Field, useFormikContext } from "formik";
import moment from "moment";
import Button from "components/Button/Button";
import AppFormMultiSelect from "components/Form/AppFormMultiSelect";
import { sleep } from "utils/utils";
import { useTranslation } from "react-i18next";
import { useToast } from "hooks/useToast";
import { ReactComponent as SearchSvg } from "assets/icons/toast/icon-search.svg";
import { ReactComponent as DownloadSvg } from "assets/icons/toast/icon-download.svg";
import { ReactComponent as CheckSvg } from "assets/icons/toast/icon-check.svg";
import { authHeader } from "services/API";
import Axios from "axios";

import FileSaver from "file-saver";
import AppFormSelectField from "components/Form/AppFormSelectField";
import { ActivedServices } from "interfaces/client";

interface FormExportProps {
  supportsOptions: OptionType[] | undefined;
  activedServicesOptions: OptionType[] | undefined;
  submitCsvRequest: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    values: any
  ) => void;
}

enum PeriodType {
  GLOBAL = "GLOBAL",
  YEARLY_MONTHLY = "YEARLY_MONTHLY",
  CUSTOM = "CUSTOM",
}

const FormExport: React.FC<FormExportProps> = ({
  supportsOptions,
  activedServicesOptions,
  submitCsvRequest,
}) => {
  const { initialValues, setValues, values } = useFormikContext();
  const { t } = useTranslation();
  const formikValues = values as Record<string, any>;

  const reset = () => {
    setValues(initialValues);
  };
  const getMultiSelectValues = (key: string) => {
    if (formikValues && formikValues[key] && Array.isArray(formikValues[key])) {
      return Object.values(formikValues[key]).map((value) => ({
        label: value,
        value: value,
      }));
    } else {
      return [];
    }
  };

  const getYears = () => {
    const currentYear = moment().format("YYYY-MM");
    const difference = moment(currentYear).diff("2020", "years");
    const years: OptionType[] = [];
    for (let i = 0; i <= difference; i++) {
      years.push({
        label: moment("2020").add(i, "y").format("YYYY"),
        value: moment("2020").add(i, "y").format("YYYY"),
      });
    }
    return years;
  };

  const getAvailableMonths = () => {
    const months: OptionType[] = [];
    const momentMonths = moment.months();
    momentMonths.forEach((_, index) => {
      const month = moment(formikValues.year).add(index, "M");
      if (month.isBefore(moment())) {
        const formattedMonthValue = month.format("MM-YYYY");
        const formattedMonthLabel = month.format("MMMM");
        months.push({ label: formattedMonthLabel, value: formattedMonthValue });
      }
    });
    return months;
  };

  const getIsDisabled = (field: string) => {
    if (formikValues.period === PeriodType.YEARLY_MONTHLY && field === "year") {
      return false;
    }
    if (
      formikValues.period === PeriodType.YEARLY_MONTHLY &&
      formikValues.year !== "" &&
      field === "month"
    ) {
      return false;
    }
    if (formikValues.period === PeriodType.CUSTOM && field === "startingDate") {
      return false;
    }
    if (
      formikValues.period === PeriodType.CUSTOM &&
      formikValues.startingDate !== "" &&
      field === "endingDate"
    ) {
      return false;
    }
    return true;
  };

  const getValue = (field: string, options: OptionType[]) => {
    const value = formikValues[field];
    if (value === undefined || value === null || value === "") {
      return null;
    }
    for (let i = 0; i < options.length; i++) {
      if (options[i].value == value) {
        return options[i];
      }
    }
    return { label: value, value: value };
  };

  const onChangePeriod = (selected: OptionType) => {
    if (selected.value === PeriodType.GLOBAL) {
      setValues({
        ...formikValues,
        period: selected.value,
        year: "",
        month: "",
        startingDate: "",
        endingDate: "",
      });
    }
    if (selected.value === PeriodType.YEARLY_MONTHLY) {
      setValues({
        ...formikValues,
        period: selected.value,
        startingDate: "",
        endingDate: "",
      });
    }
    if (selected.value === PeriodType.CUSTOM) {
      setValues({
        ...formikValues,
        period: selected.value,
        year: "",
        month: "",
      });
    }
  };

  const onChangeYear = (selected: OptionType) => {
    setValues({
      ...formikValues,
      year: selected.value,
      month: "",
    });
  };

  const canSubmitCsvRequest = () => {
    return (
      formikValues.period !== "" &&
      ((formikValues.period === PeriodType.YEARLY_MONTHLY &&
        formikValues.year !== "" &&
        formikValues.month !== "") ||
        (formikValues.period === PeriodType.CUSTOM &&
          formikValues.startingDate !== "" &&
          formikValues.endingDate !== "") ||
        formikValues.period === PeriodType.GLOBAL)
    );
  };

  const selectPeriodOptions: OptionType[] = [
    {
      label: t("clientManagement.export.periods.global"),
      value: PeriodType.GLOBAL,
    },
    {
      label: t("clientManagement.export.periods.yearly-monthly"),
      value: PeriodType.YEARLY_MONTHLY,
    },
    {
      label: t("clientManagement.export.periods.custom"),
      value: PeriodType.CUSTOM,
    },
  ];
  const selectYearOptions: OptionType[] = getYears();
  const selectMonthOptions: OptionType[] = getAvailableMonths();

  return (
    <>
      <div className="d-flex flex-row mt-2">
        <div className="col-4 mr-2">
          <Field
            component={AppFormSelectField}
            labelName={t("clientManagement.export.period-label")}
            placeholder={t("clientManagement.export.period-placeholder")}
            name="period"
            options={selectPeriodOptions}
            isRequired={true}
            isSearchable={false}
            value={getValue("period", selectPeriodOptions)}
            onChange={onChangePeriod}
          />
        </div>
        <div className="col-4 mr-2">
          <Field
            component={AppFormSelectField}
            labelName={t("clientManagement.export.year-label")}
            placeholder={t("clientManagement.export.year-placeholder")}
            name="year"
            options={selectYearOptions}
            value={getValue("year", selectYearOptions)}
            onChange={onChangeYear}
            isDisabled={getIsDisabled("year")}
            isSearchable={false}
          />
        </div>
        <div className="col-4 mr-2">
          <Field
            component={AppFormSelectField}
            labelName={t("clientManagement.export.month-label")}
            placeholder={t("clientManagement.export.month-placeholder")}
            name="month"
            value={getValue("month", selectMonthOptions)}
            options={selectMonthOptions}
            isDisabled={getIsDisabled("month")}
          />
        </div>
      </div>
      <div className="d-flex flex-row">
        <div className="col-4 mr-2"></div>
        <div className="col-4 mr-2">
          <Field
            label={t("clientManagement.export.consumed-after-label")}
            component={AppFormDatePicker}
            name="startingDate"
            max={moment().format("YYYY-MM-DD")}
            disabled={getIsDisabled("startingDate")}
          />
        </div>
        <div className="col-4 mr-2">
          <Field
            label={t("clientManagement.export.consumed-before-label")}
            component={AppFormDatePicker}
            name="endingDate"
            max={moment().format("YYYY-MM-DD")}
            min={moment(formikValues.startingDate).format("YYYY-MM-DD")}
            disabled={getIsDisabled("endingDate")}
          />
        </div>
      </div>
      <div className="d-flex flex-row mt-4">
        <div className="col-4 mr-2">
          <Field
            component={AppFormMultiSelect}
            labelName={t("clientManagement.export.plan-label")}
            name="support"
            options={supportsOptions}
            value={getMultiSelectValues("support")}
            placeholder={t(
              "clientManagement.export.plan-placeholder"
            ).toLowerCase()}
            isSearchable={false}
          />
        </div>
        <div className="col-4 mr-2">
          <Field
            component={AppFormMultiSelect}
            labelName={t("clientManagement.export.service-active-label")}
            name="activedServices"
            options={activedServicesOptions}
            value={getMultiSelectValues("activedServices")}
            placeholder={t(
              "clientManagement.export.service-active-placeholder"
            ).toLowerCase()}
            isSearchable={false}
          />
        </div>
      </div>
      <div className="d-flex flex-row">
        <div className="col-12 d-flex justify-content-end ms-5 align-items-center">
          <div className="d-flex">
            <Button
              type="button"
              buttonType="delete"
              className="btn-cta"
              title="Clear"
              onClick={reset}
            />
            <Button
              disabled={!canSubmitCsvRequest()}
              type="button"
              buttonType="export"
              className="btn-main ml-2"
              title="Export Csv"
              onClick={(e) => submitCsvRequest(e, formikValues)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const Export: React.FC = () => {
  const { getData2 } = useAxios();
  const [supportsOptions, setSupportsOptions] = useState<OptionType[]>();
  const [activedServicesOptions, setActivedServicesOptions] =
    useState<OptionType[]>();
  const { t } = useTranslation();
  const componentRef = useRef<any>(null);
  const initialValues: any = {
    period: "",
    month: "",
    year: "",
    support: [],
    startingDate: "",
    endingDate: "",
  };
  const { addToast, updateToast, removeToast } = useToast();

  useEffect(() => {
    (async () => {
      // get supports available
      const { data: dataSupports } = await getData2<any>("admin/supports");
      const supportsOptions = dataSupports.map((support: any) => ({
        label: support.name.toLowerCase(),
        value: support.name,
      }));
      supportsOptions.push({ label: "Trial", value: "Trial" });
      setSupportsOptions(supportsOptions);

      // get actived services available
      const activedServicesOptions: OptionType[] = Object.entries(
        ActivedServices
      ).map(([value]) => ({
        value: value,
        label: value.toLowerCase().replace(/_/g, " "),
      }));

      setActivedServicesOptions(activedServicesOptions);
    })();
  }, []);

  const submitCsvRequest = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    values: any
  ) => {
    const valuesCopy: any = { ...values };
    Object.keys(valuesCopy).forEach((key) =>
      valuesCopy[key] === undefined || valuesCopy[key] === ""
        ? delete valuesCopy[key]
        : {}
    );
    const params: any = { ...valuesCopy };
    if (
      valuesCopy.period === PeriodType.YEARLY_MONTHLY ||
      valuesCopy.period === PeriodType.CUSTOM
    ) {
      if (valuesCopy.month) {
        params.startingDate = moment(valuesCopy.month, "MM-YYYY")
          .startOf("month")
          .format();
        params.endingDate = moment(valuesCopy.month, "MM-YYYY")
          .endOf("month")
          .format();
      }
      if (!valuesCopy.month) {
        params.startingDate = moment(valuesCopy.year, "YYYY")
          .startOf("year")
          .format();
        params.endingDate = moment(valuesCopy.year, "YYYY")
          .endOf("year")
          .format();
      }
      if (valuesCopy.period === PeriodType.CUSTOM) {
        params.startingDate = moment(valuesCopy.startingDate).format();
        params.endingDate = moment(valuesCopy.endingDate).format();
      }
    }
    const id = addToast(() => (
      <>
        <SearchSvg height={100} />
        <span className="pl-1">
          {t("clientManagement.export.toast.exporting")}
        </span>
      </>
    ));
    await sleep(500);
    const url = "admin/clients/csv";
    Axios.get(url, {
      responseType: "blob",
      headers: authHeader(),
      params,
      onDownloadProgress: () => {
        updateToast(id, () => (
          <>
            <DownloadSvg height={100} />
            <span className="pl-1">
              {t("clientManagement.export.toast.downloading")}
            </span>
          </>
        ));
      },
    }).then(async (data) => {
      await sleep(500);
      FileSaver.saveAs(
        data.data,
        `user_consumption_${moment().format("YYYY_MM_DD_HH_mm_ss_Z")}.csv`
      );
      updateToast(id, () => (
        <>
          <CheckSvg height={40} />
          <span className="pl-1">
            {t("clientManagement.export.toast.success")}
          </span>
        </>
      ));
      await sleep(500);
      removeToast(id);
    });
  };

  return (
    <div className="client-list-form-export">
      <div ref={componentRef} className="client-list-form-export-container">
        <AppForm
          enableReinitialize
          initialValues={initialValues}
          onSubmit={() => {}}
        >
          <FormExport
            supportsOptions={supportsOptions}
            activedServicesOptions={activedServicesOptions}
            submitCsvRequest={submitCsvRequest}
          />
        </AppForm>
      </div>
    </div>
  );
};

export default Export;
