import React from 'react';
import { FieldProps } from 'formik';
import 'assets/scss/appForm.scss';

interface ICustomFormFieldProps {
    placeholder?: string,
    isError: boolean,
    icon?: string,
    id?: string,
    isRequired?: boolean,
    labelName?: string,
    customClass?: string,
    prefix?: string
}

/*
 Custom Formik input field
*/

const AppFormField = ({
    isError,
    icon,
    id,
    isRequired,
    labelName,
    customClass,
    field: { name, onBlur, onChange, value },
    form: { errors, touched, setFieldTouched },
    prefix,
    ...props
}: ICustomFormFieldProps & FieldProps) => {
    const hasError = errors[name] && touched[name];

    const blur = () => {
        setFieldTouched(name);
        onBlur(name);
    };

    const getInputClassName = () => {
        const classes = ['form-control', 'input-text', isError ? 'is-invalid' : ''];
        if (customClass) {
            classes.push(customClass);
        }
        return classes.join(' ');
    };

    return (
        <div className='form-group mb-3'>
            <label htmlFor={id} className={`mb-2 ${isError ? 'is-invalid' : ''}`} >{labelName}
                {isRequired && <sup>*</sup>}
            </label>
            <div className={`${icon ? `icon-${icon}` : ''} ${isError ? 'is-invalid' : ''}`} >
                <div className='input-container'>
                    {prefix &&
                        <span className='input-prefix'>{prefix}</span>
                    }
                    <input
                        onChange={onChange}
                        onBlur={blur}
                        autoCapitalize="none"
                        value={value}
                        name={name}
                        className={getInputClassName()}
                        {...props}
                    />
                </div>
                {hasError && <i className='is-invalid'>{errors[name]}</i>}
            </div>
        </div >
    );
};

export default AppFormField;
